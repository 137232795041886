export default class NavController {
    constructor() {
        this.bodyEl = document.body;
        this.navigation = document.querySelector('nav');
        this.navLinks = document.querySelector('.nav-links');
        this.menuBtn = document.querySelector('.menu-btn');
        this.linksEl = document.querySelectorAll('.nav-links__link');
        this.navHeight = this.navigation.clientHeight;
    }

    init() {
        let self = this;

        self.menuBtn.addEventListener('click', () => {
            self.bodyEl.classList.toggle('no-scroll');
            self.menuBtn.classList.toggle('open');
            self.navLinks.classList.toggle('open');
            self.linksEl.forEach((el) => {
                el.classList.toggle('open');
            });
        });
    }
}
