// This is the scss entry file
import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
import "bootstrap/dist/js/bootstrap.bundle";

// We can import other JS file as we like
import "../components/sidebar";

// import Swiper JS
import Swiper from 'swiper/bundle';

import NavController from "./controllers/nav_controller";


window.document.addEventListener("DOMContentLoaded", function () {
  const navcntr = new NavController();
  navcntr.init();

  // nav
  const navigation = document.querySelector('nav');
  const navLogo = document.querySelector('.nav-logo-svg');

  function handleScroll() {
    const scrolledToTop = window.scrollY === 0;
    if (scrolledToTop) {
      navigation.classList.remove('scrolled');
      navLogo.classList.remove('scrolled');
    } else {
      navigation.classList.add('scrolled');
      navLogo.classList.add('scrolled');
    }
  }

  window.addEventListener('scroll', handleScroll);

  //cookie
  // Get references to the accept and reject buttons and the cookie banner
  const acceptButton = document.getElementById('accept-cookies');
  const rejectButton = document.getElementById('reject-cookies');
  const cookieBanner = document.getElementById('cookie-banner');

  // Function to set a cookie
  function setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  function consentGranted() {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('consent', 'update', {
      'analytics_storage': 'granted',
      'ad_storage': 'granted'
    });

    gtag('config', 'GTM-NZNSSJ36');
  }

  // Function to handle the acceptance of cookies
  function acceptCookies() {
      setCookie('CookiesAccepted', 'true', 365);
      consentGranted();
      cookieBanner.style.display = 'none';
  }

  // Function to handle the rejection of cookies
  function rejectCookies() {
      setCookie('CookiesAccepted', 'false', 30);
      cookieBanner.style.display = 'none';
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // Check if the user has previously accepted or rejected analytics cookies
  const analyticsCookiesAccepted = getCookie('CookiesAccepted');

  // Show the cookie banner only if the user hasn't made a choice before
  if (!analyticsCookiesAccepted) {
      cookieBanner.style.display = 'block';
  } 
  if (analyticsCookiesAccepted && analyticsCookiesAccepted === 'true') {
    consentGranted();
  }

  // Add click event listeners to the accept and reject buttons
  acceptButton.addEventListener('click', acceptCookies);
  rejectButton.addEventListener('click', rejectCookies);

  //auto play video
  function playVideo() {
    var video = document.querySelector('.product_video');
    if (video) {
        video.play(); 
    }
}

if (document.querySelector('.product_video')) {
    playVideo();
}

  // landing heading slider
  if(document.querySelector('.h-slide')) {
    const slides = document.querySelectorAll(".h-slide");
    let currentSlideIndex = 0;
  
    slides[0].classList.add('active');
    function changeSlide() {
      slides[currentSlideIndex].classList.remove("active");
      currentSlideIndex = (currentSlideIndex + 1) % slides.length;
      slides[currentSlideIndex].classList.add("active");
    }
    
    setInterval(changeSlide, 4000);
  }

  // card slider
  if(document.querySelector('.h-card')) {
    const slides = document.querySelectorAll(".h-card");
    let currentSlideIndex = 0;
  
    slides[0].classList.add('active');
    function changeSlide() {
      slides[currentSlideIndex].classList.remove("active");
      currentSlideIndex = (currentSlideIndex + 1) % slides.length;
      slides[currentSlideIndex].classList.add("active");
    }
    
    setInterval(changeSlide, 10000);
  }

  // about cards slider
  if(document.querySelector('.h-about')) {
    const aboutSlides = document.querySelectorAll(".h-about");
    let currentAboutIndex = 0;
  
    aboutSlides[0].classList.add('active');
    function changeSlide() {
      aboutSlides[currentAboutIndex].classList.remove("active");
      currentAboutIndex = (currentAboutIndex + 1) % aboutSlides.length;
      aboutSlides[currentAboutIndex].classList.add("active");
    }
    
    setInterval(changeSlide, 10000);
  }

  //animations
  const options = {
    rootMargin: '0px',
    threshold: 0.1,
  };

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
      }
    });
  }, options);
  
  const animatedDivs = document.querySelectorAll('.a-div');
  const opacityDivs = document.querySelectorAll('.o-div');
  const raisingDiv = document.querySelectorAll('.m-div');
  const teaserEvenDivs = document.querySelectorAll('.l-div');
  const teaserOddDivs = document.querySelectorAll('.r-div');
  const crownDivs = document.querySelectorAll('.crowns');

  animatedDivs.forEach((div) => {
    observer.observe(div);
  });

  opacityDivs.forEach((div) => {
    observer.observe(div);
  });

  raisingDiv.forEach((div) => {
    observer.observe(div);
  });
 
  teaserEvenDivs.forEach((div) => {
    observer.observe(div);
  });

  teaserOddDivs.forEach((div) => {
    observer.observe(div);
  });

  crownDivs.forEach((div) => {
    observer.observe(div);
  });



  // delaying animation
  const delayObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
      }
    });
  }, options);

  // for dog - coworker
  const dogsDivs = document.querySelectorAll('.t-coworkers__imgs-img');
  let dogs_counter = 1;
  dogsDivs.forEach((div) => {
    delayObserver.observe(div);
    div.style.animationDelay = `${dogs_counter * 0.5}s`;
    dogs_counter += 1;
  });

  // for product list
  const productsDivs = document.querySelectorAll('.product__container');
  let products_counter = 1;
  productsDivs.forEach((div) => {
    delayObserver.observe(div);
    div.style.animationDelay = `${products_counter * 0.2}s`;
    products_counter += 1;
  });

  // for recipe list
  const recipesDivs = document.querySelectorAll('.recipe__container');
  let recipes_counter = 1;
  recipesDivs.forEach((div) => {
    delayObserver.observe(div);
    div.style.animationDelay = `${recipes_counter * 0.2}s`;
    recipes_counter += 1;
  });

  //   // for teaser
  // const teaserDivs = document.querySelectorAll('.teaser-info');
  // let counter = 1
  // teaserDivs.forEach((div) => {
  //   delayObserver.observe(div);
  //   div.style.animationDelay = `${counter * 0.5}s`;
  //   counter += 1;
  // });

  //products slider 
  let items = document.querySelectorAll('.car-landing .car-landing-item');

  items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (var i=1; i<minPerSlide; i++) {
          if (!next) {
              // wrap carousel by using first child
            next = items[0];
          }
          let cloneChild = next.cloneNode(true);
          el.appendChild(cloneChild.children[0]);
          next = next.nextElementSibling;
      }
  });

    //benefits slider 
    let benefits = document.querySelectorAll('.benefit-landing .benefit-landing-item');
    let benefitSlides = document.querySelectorAll('.benefit-landing-item');
    if(benefitSlides.length) {
      benefitSlides[0].classList.add('active');
    }

    benefits.forEach((el) => {
        const minPerSl = 4;
        let next = el.nextElementSibling;
        for (var i=1; i<minPerSl; i++) {
            if (!next) {
                // wrap carousel by using first child
              next = benefits[0];
            }
            let cloneChild = next.cloneNode(true);
            el.appendChild(cloneChild.children[0]);
            next = next.nextElementSibling;
        }
    });

    // swiper for product home page
    const homeProductsSwiper = new Swiper('.home-products-swiper', {
      // Optional parameters
      slidesPerView: 1,
      spaceBetween: 5,
      autoplay: {
        delay: 3000,
      },
      direction: 'horizontal',
      loop: true,
      breakpoints: {
        700: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  
    // swiper for product detail page
    const productsSwiper = new Swiper('.products-swiper', {
      // Optional parameters
      autoplay: {
        delay: 15000,
      },
      direction: 'horizontal',
      slidesPerView: 'auto',
      loop: true,
      spaceBetween: 5,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    // swiper for product detail page
    const benefitsSwiper = new Swiper('.benefits-swiper', {
      // Optional parameters
      autoplay: {
        delay: 0,
      },
      direction: 'horizontal',
      speed: 6000,
      slidesPerView: 4,
      loop: true,
      spaceBetween: 5,
    });


});
